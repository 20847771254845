import React from 'react'

import Hero from './sections/01-Hero'

const Home = () => {
  return (
    <>
      <Hero/>
    </>
  )
}

export default Home